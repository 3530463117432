 /* palette */

//  color
$color_dimgray: #F7F7F7;
$color_main: #082D6E;
$color_gray: #828282;

// transition
$transition_default: all 1.5s cubic-bezier(0.42, 0, 0.18, 0.97);
$transition-pattern: all 1s cubic-bezier(0.42, 0, 0.18, 0.97);

// size
$gap-bottom-title: 2rem;
$size-header: 64px;
$size-header-m: 50px;

// shadow
$shadow-4dp : 0px 1px 3px rgba(0, 0, 0, 0.16);
$shadow-8dp : 0px 3px 6px rgba(0, 0, 0, 0.16);

// border
$border-test: 1px solid black;


