
header{
    position: fixed;
    top: 0;
    z-index: 100;

    background-color: white;
    z-index: 10;
    width: 100%;
    height: $size-header;
    box-sizing: content-box;
    box-shadow: $shadow-4dp;
}

header .con-default{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Header__logo img{
    height: 48px;
}

.Header__gnb{
    display: flex;
    text-transform: uppercase;
    font-size: .9rem;
}

.Header__gnb li{
    margin-left: 24px;
}

.Header__gnb li:after{
    content: "/";
    display: inline-block;
    margin-left: 24px;
}

.Header__gnb li:last-child:after{
    content: "";
}

.gnb-active{
    color: #082D6E;
}

// gnb mobile
.gnb-mobile-wrap{
    display: none;
    position: relative;
    width: 32px;
    height: 32px;
}

.gnb-mobile-wrap input{
    display: none;
}
.gnb-mobile-icon{
    width: 32px;
    height: 32px;
    display: inline-block;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    transition: $transition_default;
}

.ic-menu{
    z-index: 1;
    opacity: 1;
}

.ic-close{
    z-index: 10;
    opacity: 0;
    // opacity: 1;
}

.gnb-mobile{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 70%;
    min-width: 200px;
    right: -70%;
    // right: 0;
    z-index: 5;
    background-color: white;
    transition: $transition_default;
}

.gnb-mobile{
    padding: 0 20px;
    padding-top: $size-header;
    box-shadow: $shadow-8dp
}

.gnb-mobile li{
    margin-bottom: 1.5rem;
    text-transform: capitalize;
}

.gnb-mobile li a{
    font-size: 1.6rem;
}

.cover-menu{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    opacity: 0;
    transition: $transition_default;
}

.gnb-mobile-wrap input:checked ~.gnb-mobile{
    right: 0;
}
.gnb-mobile-wrap input:checked ~.ic-close {
    opacity: 1;
}
.gnb-mobile-wrap input:checked ~.ic-menu{
    opacity: 0;
}
.gnb-mobile-wrap input:checked ~.cover-menu{
    opacity: 1;
}


// desktop
@media only screen and (max-width: 1200px) {
  
}

// tablet
@media only screen and (max-width: 768px) {
    header{
        height: $size-header-m;
    }
    .Header__logo img{
        height: 32px;
    }
    .gnb-web{
        display: none;
    }
    .gnb-mobile-wrap{
        display: block;
    }
}

// mobile
@media only screen and (max-width: 600px) {
    
}