@import './partial/palette';

// ProjectDetails
.size-header.detail{
    width: 100%;
    overflow: hidden;
}

.detail-wrap{
    width: 400px;
    border-bottom: 1px solid $color_gray;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.detail-wrap p{
    line-height: 2;
    font-size: .8rem;
}



.contents:last-of-type{
    margin-bottom: 200px;
}

.width-description{
    width: 50%;
    padding: 1rem 0 .4rem 0;
    font-size: .8rem;
}

.width-description p{
    font-size: .8rem;
}

.projectImage img{
    width: 100%;
    padding: 40px 0;
    vertical-align: top;
}

.projectSession{
    padding-top: 100px;
}

.projectSession h3{
    font-size: 1.4rem;
    color: $color_main;
    margin-bottom: 1rem;
}


// desktop
@media only screen and (max-width: 1200px) {

}

// tablet
@media only screen and (max-width: 768px) {
    .width-description{
        width: 100%;
    }

    .projectSession{
        padding-top: 40px;
    }
    .projectImage img{
        padding: 20px 0;
    }
}

// mobile
@media only screen and (max-width: 600px) {
   
    
}