footer{
    width:100%;
    padding: 64px 0;
    background-color: $color_main;
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer img{
    height: 64px;
    margin-bottom: 24px;
}

footer p{
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.8rem;
    font-weight: 100;
}


// desktop
@media only screen and (max-width: 1200px) {

}

// tablet
@media only screen and (max-width: 768px) {
    footer{
        padding: 20px 0;
    }
    footer img{
        height: 40px;
        margin-bottom: 12px;
    }
}

// mobile
@media only screen and (max-width: 600px) {
   
}