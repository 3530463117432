@import './palette.scss';

 *{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }
 
  /* font */
  html{
    font-size: 14pt;
  }
  body{
    position: relative;
    font-family: 'NanumSquare', sans-serif; 
    font-weight: 400;
  }
  p, a{
    font-size: 1rem;
    line-height: 1.8;
    color: $color_gray;
  }

  h1, h2, h3{
    font-size: 2rem;
  }

  h2, h3{
    font-weight: 400;
    color: $color_main;
  }
 
  /* color */
 body{
   background-color: $color_dimgray;
 }

 /* container */
 .con-default{
   width: 80%;
   margin: auto;
 }
 
 .sec-full{
  padding-top: 160px;
  height: 100vh;
 }

 .size-header{
   padding-top: $size-header;
 }



// desktop
@media only screen and (max-width: 1200px) {
  
}

// tablet
@media only screen and (max-width: 768px) {
  html{
    font-size: 12pt;
  }
  .con-default{
    width: 90%;
  }
  .sec-full{
    padding-top: 120px;
  }
}

// mobile
@media only screen and (max-width: 600px) {
  
}

.wrap-notfound{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}
