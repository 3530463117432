
.contact-list h3 {
    font-size: 3rem;
    font-weight: 100;
    text-transform: capitalize;
    margin-left: 20px;
}

.contact-list p {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
}
