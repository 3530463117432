.pagetitle h2{
    padding-top: 4rem;
    text-transform: capitalize;
    padding-bottom: 40px;
}

// desktop
@media only screen and (max-width: 1200px) {

}

// tablet
@media only screen and (max-width: 768px) {
    .pagetitle h2{
        padding-top: 40px;
        padding-bottom: 30px;
    }
}

// mobile
@media only screen and (max-width: 600px) {
   
    
}