.contact{
    position: relative;
    height: 100vh;
}

.contact-map{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50vh;
}

.contact-map .con-default{
    position: relative;
    height: 100%;

    // background-image: url('../img/map_image.png');
    background-color: gray;
    background-position: center;
    background-size: cover;
}

