@import './partial/contactTable';
@import './partial/pageTitle';
@import './partial/contactMap';



// desktop
@media only screen and (max-width: 1200px) {

}

// tablet
@media only screen and (max-width: 768px) {
    .contact-map .con-default{
        width: 100%;
    }

    .contact-map{
        bottom: 0;
        width: 100%;
        height: 35vh;
    }

}

// mobile
@media only screen and (max-width: 600px) {
   
    
}