.sec-story {
    position: relative;
}

// make color

.blue .sec-story {
    background-color: $color_dimgray;
}

.blue .sec-story::before {
    background-color: $color_main;
}

.blue h3 {
    color: white;
}

.blue p,
.blue .sec-story a,
.blue .sec-story {
    color: white;
}

.blue .__cover-btn li:after {
    background-color: white;
}

// design study
.white .sec-story {
    background-color: $color_main;
}

.white .sec-story::before {
    background-color: $color_dimgray;
}

.white h3 {
    color: $color_main;
}

.white p,
.white .sec-story {
    color: $color_gray;
}

.white .sec-story a {
    color: $color_main;
}

.white .__cover-btn li:after {
    background-color: $color_main;
}

// styling
.sec-story::before {
    content: "";
    display: block;
    position: absolute;
    width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
}

.sec-story .con-default {
    position: relative;
    z-index: 1;
    height: 100%;
}

.story-title {
    display: flex;
    align-items: center;
    margin-bottom: $gap-bottom-title;
}

.story-title img {
    height: 64px;
}

.story-title h3 {
    font-size: 2rem;
    font-weight: 100;
    text-transform: capitalize;
    margin-left: 0.6rem;
}

.story-text {
    width: 40%;
}

.story-text p {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
}

.sec-story .__cover-btn{
    bottom: 20%;
}


// image slide
.img-slide {
    position: absolute;
    top: 0;
    left: 70%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 40%;
    height: 60vh;
    overflow: hidden;
}

.slide-wrap {
    position: relative;
    left: 0;
    height: 100%;
    display: flex;
    transition: all .8s ease-in-out;
}

.slide-wrap li {
    background-color: $color_main;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
}