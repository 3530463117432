@media only screen and (max-width: 1500px) {
    .contents-text{
        height: 60%;
    }
    .contents-text-detail{
        display: none;
    }

    .contents-text-detail p{
        height: 3.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        line-height: 1.2rem;
    }
}

// desktop
@media only screen and (max-width: 1200px) {

    .contents-wrap{
        width: 80%;
    }
    p.post-client{
        // display: none;
    }


    .post-wrap{
        >a{
            width: calc(50% - 10px);
            margin-bottom: 20px;
            margin-right: 20px;

            &:nth-child(3n){
                margin-right: 20px;
            }

            &:nth-child(2n){
                margin-right: 0;
            }
        }
    }
}

// tablet
@media only screen and (max-width: 768px) {
    .fillter{
        padding: 20px 0;
    }
    .fillter ul{
        width: 100%;
        display: flex;
    }
    .fillter li{
        width: inherit;
        padding: 0.3rem 0.6rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .Nothing{
        width: 90%;
    }

}

// mobile
@media only screen and (max-width: 600px) {
    .post-wrap{
        >a{
            width: 100%;
            margin-right: 0;

            &:nth-child(3n){
                margin-right: 0;
            }

            &:nth-child(2n){
                margin-right: 0;
            }
        }
    }
    .contents-text-detail{
        height: 50%;
        opacity: 1;
    }

    .contents-text-thumb .post-title{
        font-size: 1rem;
        line-height: 1.4;
    }

}