@import './headerAdmin.scss';
@import '../partial/palette.scss';

.admin {
    position: relative;
    z-index: 10;
    height: 100vh;
    background-color: $color_dimgray;
}

.con-admin {
    width: 800px;
    margin: auto;
    position: relative;
}

.adminTitle {
    padding-top: 50px;
    margin-bottom: 30px;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adminTitle h3 {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: bold;
}

// contents
.adminSecTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 40px;
}

.adminSecTitle h4 {
    font-weight: 700;
    text-transform: capitalize;
}

.admin .btn {
    color: black;
    cursor: pointer;
}

// home - slider
input[type="file"] {
    // display: none;
}

.wrap-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-login{
    border-top: 1px solid $color_main;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    margin: 20px 0;
    color: $color_main;
    cursor: pointer;
}

.SlideLists {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.SlideList {
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 190px;
    margin: 5px;
    height: 150px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.SlideList img {
    width: 100%;
    vertical-align: top;
}

.SlideList .btn-del {
    position: absolute;
    z-index: 10;
    font-size: 0.6rem;
    top: 5px;
    right: 5px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}


// portfolio
.PortfolioList {
    background-color: white;
    width: 100%;
    height: 200px;
    position: relative;
    margin-bottom: 20px;
    display: flex;
}

.PortfolioList {
    .wrap-img{
        margin-right: 20px;
        width: 40%;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
}

.PortfolioList .wrap-text {
    padding-top: 20px;
    width: 50%;
    float: right;
    h5, p{
        padding-right: 2rem;
        font-size: .8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        line-height: 1.2rem;
    }
}

.PortfolioList .wrap-btn {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 20px;

}

.PortfolioList .wrap-btn p {
    font-size: 0.8rem;
    margin-left: 20px;
}


// portfolio submit form
.section-hide {
    display: none;
}

.section-block {
    display: block;
}

.wrap-btn {
    display: flex;
    justify-content: flex-end;
}

.portfolioBtn {
    text-align: right;
}

.input-text {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
}

.input-text p {
    width: 30%;
    font-size: 0.8rem;
    color: black;
    line-height: 40px;
    padding-left: 20px;
}

.input-text input {
    width: 70%;
    height: 40px;
    border-radius: 10px;
    border: 0;
    padding-left: 15px;
    line-height: 40px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    transition: $transition_default;
    font-size: 0.8rem;
    color: $color_gray;
}

.input-text input:focus {
    outline: none !important;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
}

.filebox {
    width: 70%;
    background-color: white;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.filebox .upload-name{
    background-color: transparent;
    width: 80%;
    box-shadow: 0 0 0 transparent;
}

.filebox label {
    display: inline-block;
    width: 20%;
    height: 40px;
    line-height: 40px;
    text-align: center;

    background-color: white;
    color: $color_gray;
    font-size: 0.8rem;
    vertical-align: middle;

    border-radius: 10px;
    cursor: pointer;
}

.input-text input[type="file"] {
    border: 2px solid blue;
    width: auto;

    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.preview-img{
    width: 100%;
    padding-top: 60%;
    background-color: rgb(240, 240, 240);
    border-radius: 0 0 10px 10px;

    background-size: cover;
    background-position: center;
    background-image: url('../../img/01.jpg');
}

.wrap-btn.wrap-btn-confirm{
    border-top: 1px solid black;
    justify-content: center;
    padding-top: 10px;
}

.wrap-btn .btn-confirm{
    color: $color_main;
    padding: 5px 20px;
    border-radius: 10px;
    cursor: pointer;

}

.InputContents{
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.input-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.input-contents-radio{
    font-size: 0.8rem;
}

.input-contents-radio label{
    margin-right: 20px;
}

.input-contents-radio input{
    margin-right: 10px;
}

.input-body .input-text p{
    padding-left: 0;
}

.btn-contents-delete{
    display: none;
}

.InputContents:last-of-type .btn-contents-delete{
    display: block;
}

.InputContents:first-of-type .btn-contents-delete{
    display: none;
}

.btn{
    cursor: pointer;
}

.preview img{
    width: 100%;
    margin-top: 50px;
    
}