.Loading{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  &.none{
    display: none;
  }
}
$dotSize: 20px;
$dotWrapWidth: 2vw;

.wrap-dot ul{
  display: flex;
  width: $dotWrapWidth;
  justify-content: center;
}

.wrap-dot li{
  width: $dotSize;
  height: $dotSize;
  background-color: white;
  border-radius: $dotSize/2;

  animation: loadingAnimation;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-play-state: running;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  
}

@keyframes loadingAnimation{
  from{
    transform: translateX($dotWrapWidth/2*-1);
  }
  to{
    transform: translateX($dotWrapWidth/2);
  }
}