@import '../partial/palette';
@import '../partial/default';

#header-admin{
    position: fixed;
}

.Header__logo{
    display: flex;
    align-items: center;
}

.Header__logo h3{
    display: inline-block;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-left: 20px;
    color: black;
}