.posts{
    padding-bottom: 200px;
}

.post-wrap{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    >a{
        width: calc(33.3% - 30px);
        margin-bottom: 30px;
        margin-right: 30px;

        &:nth-child(3n){
            margin-right: 0;
        }
    }
}

.post{
    width: 100%;

    position: relative;
    padding-top: 70%;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 4px 5px rgba(0,0,0,0.16);
    cursor: pointer;
    transition: $transition-default;
}

.post-thumb{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: $transition-default;
    background-size: cover;
    background-position: center;
}

.post-contents{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 4%;
    
}

.contents-wrap{
    width: 60%;
    position: relative;
}

.contents-text{

    display: flex;
    flex-direction: column;
    width: 100%;
    transition: $transition-default;
    background-color: white;
    padding: 0.6rem;
    position: relative;
} 


.post-title{
    color: $color_main;

}

.post-client{
}

.post-title, .post-client, .post-date{
    position: relative;
    z-index: 1;
    background-color: white;
    transition: $transition-default;
}

.text-thumb-wrap{
    width: 90%;
    bottom: 0;
    margin-bottom: 0.2rem;
}

.contents-text-detail{
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: $transition-default;
    background-color: white;
}

.contents-text .post-client{
    position: relative;
    z-index: 0;
}

.contents-text h5{
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4;

    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; /* 라인수 */ 
    -webkit-box-orient: vertical; 
    word-wrap:break-word;


}

.contents-text p{
    font-size: .7rem;
    padding-top: 2px;
    padding-bottom: 0;
}



.contents-text-detail p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 1.2rem;
}

p.post-client{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 1.2rem;
}


.contents-text-detail:before{
    margin-bottom: .2rem;
    content: "";
    display: block;
    position: relative;
    border-top: 1px solid $color_gray;
    width: 100%;
}

.contents-text-detail p{
}

// hover
.post:hover{
    box-shadow: 0px 12px 17px rgba(0,0,0,0.16);
}
.post:hover .post-thumb{
    transform: scale(1.03);
    filter: blur(4px) brightness(0.8);
    -webkit-filter: blur(4px) brightness(0.8);
    -moz-filter: blur(4px) brightness(0.8);
    -o-filter: blur(4px) brightness(0.8);
    -ms-filter: blur(4px) brightness(0.8);
}

.post:hover .contents-text{
    background-color: white;
    height: 100%;
    max-height: 360px;
}
.post:hover .contents-text-detail{
    opacity: 1;
    height: 100%;
    max-height: 1000px;
}

.post:hover .post-client::after{
    height: 200%;
}