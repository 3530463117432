

// desktop
@media only screen and (max-width: 1200px) {
    // story
    .story-title img{
        height: 40px;;
    }
    .story-title h3{
        font-size: 1.5rem;
    }
    .__cover-btn{
        margin-top: 40px;
    }
}

// tablet
@media only screen and (max-width: 768px) {
    .sec-full.sec-story{
        padding-top: 10%;
    }

    .__cover-text{
        width: 100%;
    }
    .__cover-text h1{
        width: 80%;
        font-size: 1.7rem;
    }

    .story-text {
        width: 100%;
    }
    .sec-story::before{
        width: 100%;
    }

    .sec-story .__cover-btn{
        position: relative;
        top: 2rem;
    }

    .img-slide{
        width: 90%;
        transform: none;
        left: 5%;
        top: initial;
        bottom: 5%;
        height: 40vh;
    }
    
    .story-text p{
        font-size: 0.7rem;
    }

    .contact-list h5, .contact-list p{
        font-size: 0.7rem;
    }

}

// mobile
@media only screen and (max-width: 600px) {
    .story-title img{
        height: 30px;
        margin-bottom: 5px;
    }
    .story-title{
        flex-direction: column;
        align-items: flex-start;
    }

    .sec-story .__cover-btn{
        position: absolute;
        left: 0.5rem;
        top: initial;
        bottom: calc(40vh - 0.5rem);

    }

    .sec-story .__cover-btn li{
        margin-bottom: 0;
    }

    .contact-list{
        align-items: flex-start;
    }
    .contact-list h5{
        width: 30%;
        line-height: 2;
    }
    .contact-list p{
        width: 70%;
    }
}