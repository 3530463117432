// ccover
html{
    scroll-behavior: smooth;
}

.sec-cover{
    position: relative;
    background-color: white;
}

.__cover-text{
    width: 40%;
}

.__cover-text h1{
    width: 250%;
    color: $color_main;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: $gap-bottom-title;
}

.__cover-text p{
    font-size: 0.8rem;
    line-height: 1.8;
}

.__cover-btn{
    display: inline-block;
    margin-top: 200px;
}

.__cover-btn li{
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 50px;
    cursor: pointer;
}

.__cover-btn li:after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -20%;
    width: 150%;
    height: 1px;
    background-color: $color_main;
    transition: $transition-pattern;
}

.__cover-btn li:hover:after{
    left: -5%;
    height: 4px;
}


.__cover-btn li a{
    color: $color_main;
    text-transform: capitalize;
}

// $row-pattern: 4;
// $col-pattern: 5;
// $count: 1;
$size-pattern: 150px;
$size-pattern-dot: 15px;
$size-interaction-long: 180px;
$size-branding-long: 180px;
$size-interaction-short: 2px;

@media only screen and (min-width: 1201px){
    $row-pattern: 4;
    $col-pattern: 5; 
    $count: 1;

    @for $i from 0 through $row-pattern {
        @for $j from 0 through $col-pattern - 1{
            .pattern:nth-child(#{$count}){
                right: $size-pattern * ($j);
                bottom: $size-pattern * ($i);
            }
            $count: $count + 1;
        }
    }

    .patternWrap{
        position: absolute;
        width: $size-pattern * $col-pattern;
        height: $size-pattern * $row-pattern;
        right: 0;
        bottom: 0;
    }
}


// desktop
@media only screen and (max-width: 1200px) {
    $row-pattern: 3;
    $col-pattern: 4;
    $count: 1;


    @for $i from 0 through $row-pattern {
        @for $j from 0 through $col-pattern - 1{
            .pattern:nth-child(#{$count}){
                right: $size-pattern * ($j);
                bottom: $size-pattern * ($i);
            }
            $count: $count + 1;
        }
    }

    .patternWrap{
        position: absolute;
        width: $size-pattern * $col-pattern;
        height: $size-pattern * $row-pattern;
        right: 0;
        bottom: 0;
    }
}

// tablet
@media only screen and (max-width: 768px) {
    $size-pattern: 25vw;
    $row-pattern: 2;
    $col-pattern: 4;
    $count: 1;

    @for $i from 0 through $row-pattern {
        @for $j from 0 through $col-pattern - 1{
            .pattern:nth-child(#{$count}){
                right: $size-pattern * ($j);
                bottom: $size-pattern * ($i);
                width: 25vw;
                height: 25vw;
            }
            $count: $count + 1;
        }
    }

    .patternWrap{
        position: absolute;
        width: 25vw * $col-pattern;
        height: 25vw * $row-pattern;
        right: 0;
        bottom: 0vh;
        overflow: hidden;
        
    }

}


.pattern{
    width: $size-pattern;
    height: $size-pattern;
    transition: $transition-pattern;

    position: absolute;
}


.pattern-dot{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)  rotate(-45deg);
    width: $size-pattern-dot;
    height: $size-pattern-dot;
    
    border-radius: $size-pattern-dot /2;
    background-color: $color_main;
    transition: $transition-pattern;
}


.pattern-center{
    width: 0;
    height: 0;
    opacity: 0;

    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    background-color: $color_main;
    transition: $transition-pattern;
    border-radius: 50%;
}

.pattern-center:after{
    content: "";

    transition: $transition-pattern;
    width: 0;
    height: 0;
    opacity: 0;

    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    border: 2px solid $color_main;
    border-radius: 50%;
    display: block;
}

.pattern:before, .pattern:after{
    content: "";
    display: block;
    position: absolute;
    width: $size-pattern-dot;
    height: $size-pattern-dot;
    border-radius: $size-pattern-dot /2;
    background-color: $color_main;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: $transition-pattern;
}

// btn hover - study
.__cover-btn-talk:hover ~.patternWrap .pattern-dot{
    width: $size-interaction-long;
    height: $size-interaction-short;
}

.__cover-btn-talk:hover ~.patternWrap .pattern:before{
    width: 0px;
    height: 0px;
}
.__cover-btn-talk:hover ~.patternWrap .pattern:after{
    width: 0px;
    height: 0px;
}

.pattern-study .pattern-dot{
    width: $size-interaction-long;
    height: $size-interaction-short;
}

.pattern-study.pattern:before{
    width: 0px;
    height: 0px;
}
.pattern-study.pattern:after{
    width: 0px;
    height: 0px;
}


// btn hover - brandings
.__cover-btn-branding:hover ~.patternWrap .pattern-dot{
    width: 0;
    height: 0;
}

.__cover-btn-branding:hover ~.patternWrap .pattern:before{
    width: $size-branding-long;
    height: $size-interaction-short;
}
.__cover-btn-branding:hover ~.patternWrap .pattern:after{
    width: $size-interaction-short;
    height: $size-branding-long;
}
// animation
.pattern-branding .pattern-dot{
    width: 0;
    height: 0;
}

.pattern-branding.pattern:before{
    width: $size-branding-long;
    height: $size-interaction-short;
}
.pattern-branding.pattern:after{
    width: $size-interaction-short;
    height: $size-branding-long;
}

// btn hover - solutionDesign
.__cover-btn-design:hover ~.patternWrap .pattern{
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    opacity: 0;
}

.__cover-btn-design:hover ~.patternWrap .pattern-center{
    opacity: 1;
    width: $size-pattern-dot*2;
    height: $size-pattern-dot*2;
}

.__cover-btn-design:hover ~.patternWrap .pattern-center:after{
    opacity: 1;
    width: $size-pattern-dot*2.5;
    height: $size-pattern-dot*2.5;
}

.pattern-design.pattern{
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    opacity: 0;
}

.pattern-design.pattern ~.pattern-center{
    opacity: 1;
    width: $size-pattern-dot*2;
    height: $size-pattern-dot*2;
}

.pattern-design.pattern ~.pattern-center:after{
    opacity: 1;
    width: $size-pattern-dot*2.5;
    height: $size-pattern-dot*2.5;
}