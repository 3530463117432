@import './partial/palette';

.disignTalk .fillter{
    display: none;
}

.disignTalk .con-default h1{
    padding: 3rem 0;
    font-size: 2rem;
    font-weight: 400;
    color: $color_main;
}