// color
.story-contact .sec-story{
    background-color: $color_dimgray
}
.story-contact .sec-story::before{
    background-color: white;
}
.story-contact h3{
    color: $color_main;
}
.story-contact p, .story-study .sec-story{
    color: $color_gray;
}
.story-contact .sec-story a{
    color: $color_main;
}
.story-contact .__cover-btn li:after{
    background-color: $color_main;
}

// style
.story-contact h3{
    margin: 0;
}
.story-contact .story-text{
    width: 100%;
}
.contact-list{
    display: flex;
    align-items: center;
    padding: 2px 0;
}

.contact-list p{
    word-break: keep-all;
}
.contact-list h5{
    width: 20%;
    color: $color_main;
}
