.fillter{
    padding: 60px 0;
    background-color: $color_dimgray;
}

.fillter ul{
    display: flex;
    text-transform: capitalize;
}

.fillter li{
    background-color: white;
    padding: 8px 30px;
    color: $color_gray;
    cursor: pointer;
    transition: $transition-default;
}

.fillter li p{
    font-size: 0.8rem;
    pointer-events: none;
    transition: $transition-default;
    line-height: 1.3;
}

.fillter li:hover{
    z-index: 10;
    box-shadow: 0px 4px 5px rgba(0,0,0,0.16);
}

.fillter .fillter-active{
    background-color: $color_main;
    box-shadow: 0px 4px 5px rgba(0,0,0,0.16);
}

.fillter .fillter-active p{
    color: white;
}

